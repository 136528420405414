<template>
  <div style="overflow-x: hidden;">
    <Head></Head>
    <el-carousel height="70vh" v-if="bannerSwitch">
      <el-carousel-item v-for="(item,index) in bannerdata" :key="item.index" @click.native="bannerLink(item)">
        <div class="baaner_mb">
          <img class="small" style="width:100%;height: 100%" :src="item.img"></img>
          <span class="imgText">{{ item.text }}</span>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel v-if="bannerappSwitch">
      <el-carousel-item v-for="(item,index) in bannerdata" :key="item.index" @click.native="bannerLink(item)">
        <div class="baaner_mb">
          <img class="small" style="width:100%;height: 100%" :src="item.img"></img>
          <span class="imgText">{{ item.text }}</span>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div id="fh5co-course-categories">
      <div class="container"></div>
    </div>

    <div
      id="fh5co-counter"
      class="fh5co-counters"
      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
      data-stellar-background-ratio="0.5"
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="row">
              <div class="col-md-4 col-sm-6 text-center animate-box">
                <span class="icon"
                  ><i class="icon-registered-capital"></i
                ></span>
                <countTo
                  :startVal="0"
                  :endVal="1000"
                  :duration="1000"
                  :autoplay="true"
                  class="fh5co-counter"
                ></countTo>
                <span class="fh5co-counter-label">注册资金（万）</span>
              </div>
              <div class="col-md-4 col-sm-6 text-center animate-box">
                <span class="icon"><i class="icon-service"></i></span>
                <countTo
                  :startVal="0"
                  :endVal="10"
                  :duration="3000"
                  :autoplay="true"
                  class="fh5co-counter"
                ></countTo>
                <span class="fh5co-counter-label" @click="Software" style="cursor: pointer;">软件著作权</span>
              </div>
              <div class="col-md-4 col-sm-6 text-center animate-box">
                <span class="icon"><i class="icon-cognizance"></i></span>
                <countTo
                  :startVal="0"
                  :endVal="10"
                  :duration="3000"
                  :autoplay="true"
                  class="fh5co-counter"
                ></countTo>
                <span class="fh5co-counter-label">软件认定</span>
              </div>
              <!--<div class="col-md-3 col-sm-6 text-center animate-box">
							<span class="icon"><i class="icon-staff"></i></span>
							<span class="fh5co-counter js-counter" data-from="0" data-to="739" data-speed="5000" data-refresh-interval="50"></span>
							<span class="fh5co-counter-label">制典员工</span>
						</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyrights">
      Collect from
      <a href="http://www.cssmoban.com/" title="网站模板">网站模板</a>
    </div>

    <div id="fh5co-course">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>平台架构</h2>
            <p>开发工具、技术引擎、技术凭他、框架构成，科技助您飞跃发展。</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 animate-box">
            <div class="course">
              <a
                href="javascript:void(0);"
                class="course-img"
                style="background-image: url('img/project-1.bb3be12a.jpg')"
              >
              </a>
              <div class="desc">
                <h3><a href="javascript:void(0);">企业应用开发平台</a></h3>
                <p>
                  数据服务 | 基础服务 | 对外系统 | 智能单元系统 | 业务单元系统 |
                  管理后台 | 模型构建 | Saas
                </p>
                <span>&nbsp;</span>
                <!--<span><a href="#" class="btn btn-primary btn-sm btn-course">Take A Course</a></span>-->
              </div>
            </div>
          </div>
          <div class="col-md-6 animate-box">
            <div class="course">
              <a
                href="javascript:void(0);"
                class="course-img"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/777777.jpg');
                "
              >
              </a>
              <div class="desc">
                <h3><a href="javascript:void(0);">移动应用开发平台</a></h3>
                <p>
                  基于android和ios系统 | 基于浏览功能框架（内置） | 开发工具 |
                  快速APP开发
                </p>
                <!--<span><a href="#" class="btn btn-primary btn-sm btn-course">Take A Course</a></span>-->
              </div>
            </div>
          </div>
          <div class="col-md-6 animate-box">
            <div class="course">
              <a
                href="javascript:void(0);"
                class="course-img"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/321.jpg');
                "
              >
              </a>
              <div class="desc">
                <h3><a href="javascript:void(0);">互联网分布式框架</a></h3>
                <p>
                  分布式调度 | 分级缓存 | 自动化运维 | 自动化运营 |
                  大数据存储分析 | PaaS | 容器计算 | 密集型计算 | 流式计算
                </p>
                <!--<span><a href="#" class="btn btn-primary btn-sm btn-course">Take A Course</a></span>-->
              </div>
            </div>
          </div>
          <div class="col-md-6 animate-box">
            <div class="course">
              <a
                href="javascript:void(0);"
                class="course-img"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/123.jpg');
                "
              >
              </a>
              <div class="desc">
                <h3><a href="javascript:void(0);">硬件一体化</a></h3>
                <p>
                  设计规划 | 采购部署 | 配置测试 | 维护保养 | 故障修复 |
                  技术支持 | OpenAI
                </p>
                <span>&nbsp;</span>
                <!--<span><a href="#" class="btn btn-primary btn-sm btn-course">Take A Course</a></span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div id="fh5co-testimonial" style="background-image: url(../static/images/school.jpg);">-->
    <!--		<div class="overlay"></div>-->
    <!--		<div class="container">-->
    <!--			<div class="row animate-box">-->
    <!--				<div class="col-md-6 col-md-offset-3 text-center fh5co-heading">-->
    <!--					<h2><span>我们的团队</span></h2>-->
    <!--				</div>-->
    <!--			</div>-->
    <!--			<div class="row">-->
    <!--				<div class="col-md-10 col-md-offset-1">-->
    <!--					<div class="row animate-box">-->
    <!--						<div class="owl-carousel owl-carousel-fullwidth">-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_1.png);"></div>-->
    <!--									<span>张清明<br><small>制典公司董事长&nbsp;&amp;&nbsp;资深银行业务专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>20年专注银行业务领域研究，致力于银行互联网化的建设与发展</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_2.png);"></div>-->
    <!--									<span>沈玮<br><small>资深云计算与互联网架构专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>10年银行系统基础架构设计与建设经验，制典首席架构师，技术总监</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_5.png);"></div>-->
    <!--									<span>李宁玉<br><small>营销数据模型与分析专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>大数据应用专业顾问</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_3.png);"></div>-->
    <!--									<span>毛澄宇<br><small>银行IT领域业务专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>15年信贷领域资深顾问，为多家银行提供专业的咨询服务</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_4.png);"></div>-->
    <!--									<span>张锋<br><small>商业票据业务与贸易融资领域资深业务专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>20年相关领域资深顾问，企业供应链与票据业务系统建设与业务咨询</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_6.png);"></div>-->
    <!--									<span>肖震<br><small>资产管理、理财服务资深业务专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>多家资产交易公司，理财平台，服务平台的系统建设与业务咨询服务</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_7.png);"></div>-->
    <!--									<span>余飞法<br><small>借贷领域资深业务专家与实施经理</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>15年来承包多家银行、集团财务公司信贷系统、供应链融系统与商业票据系统的工程建设</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_9.png);"></div>-->
    <!--									<span>覃众重<br><small>融资租赁业务与系统建设专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>致力租赁业务移动互联的扩展与研究，成功主导并支持建设十余家租赁公司的信息系统</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_10.png);"></div>-->
    <!--									<span>鲁斌<br><small>支付领域业务专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>为多家银行进行统一支付系统与核心、互联网账户系统、虚拟支付系统建设与咨询</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--							<div class="item">-->
    <!--								<div class="testimony-slide active text-center">-->
    <!--									<div class="user" style="background-image: url(../static/images/person_11.png);"></div>-->
    <!--									<span>张经纬<br><small>用户体验专家</small></span>-->
    <!--									<blockquote>-->
    <!--										<p>多年专注于用户体验、移动端和web端设计，为多家银行机构和平台提供项目解决方案</p>-->
    <!--									</blockquote>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--						</div>-->
    <!--					</div>-->
    <!--				</div>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--	</div>-->

    <div id="fh5co-blog" style="padding-top: 0em !important">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>核心能力</h2>
            <p>
              企业核心竞争力为企业独自所拥有，有力支持企业向更有生命力的新事业拓展。
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a
                href="javascript:void(0);"
                class="blog-img-holder"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/11111h.jpg');
                "
              ></a>
              <div class="blog-text">
                <h3><a href="javascript:void(0);">物联网</a></h3>
                <span class="posted_on">更懂智慧物联</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>
                  通过无线通信技术、云计算和大数据分析等手段将各种智能设备互相连接，形成一个庞大的网络。实现从传感器到终端设备、从终端设备到云端服务器的信息传递和数据交换，并且可以自主管理、监测和控制各种物理设备
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a
                href="javascript:void(0);"
                class="blog-img-holder"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/222.jpg');
                "
              ></a>
              <div class="blog-text">
                <h3><a href="javascript:void(0);">大数据</a></h3>
                <span class="posted_on">把握数据，掌握未来</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>
                  管理、处理和分析大规模数据，可应用在:
                  金融行业，零售业，医疗保健，物流运输，城市规划，能源领域，农业领域，社交媒体；帮助企业从大数据中挖掘出有价值的信息，实现数据驱动的业务创新和转型升级。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a
                href="javascript:void(0);"
                class="blog-img-holder"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/33333.jpg');
                "
              ></a>
              <div class="blog-text">
                <h3><a href="javascript:void(0);">AI人工智能</a></h3>
                <span class="posted_on">让机器像人类一样思考</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>
                  AI人工智能应用范围非常广泛，涉及到许多不同的领域；包含:
                  自然语言处理，机器学习，计算机视觉，机器人技术，自动驾驶技术，虚拟助手，区块链技术，游戏开发，医疗保健，金融服务。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a
                href="javascript:void(0);"
                class="blog-img-holder"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/4444.jpg');
                "
              ></a>
              <div class="blog-text">
                <h3><a href="javascript:void(0);">VR虚拟仿真</a></h3>
                <span class="posted_on">沉浸式体验，现实之外</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>
                  模拟真实场景技术，使用户可以与虚拟环境进行互动和沉浸式体验；被广泛应用于许多领域，如游戏、教育、医疗保健、工业设计以及军事等；让用户感觉到自己置身于虚拟世界中。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a
                href="javascript:void(0);"
                class="blog-img-holder"
                style="
                  background-image: url('https://zhidianwebiste.oss-cn-nanjing.aliyuncs.com/uploads/193.jpg');
                "
              ></a>
              <div class="blog-text">
                <h3><a href="javascript:void(0);">云计算</a></h3>
                <span class="posted_on">轻松部署，高效管理</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>
                  通过网络提供计算资源、软件和数据存储服务的技术；帮助企业实现数字化转型的技术，其弹性伸缩、高可靠性、快速部署、节约成本、数据安全性等特点可以减轻企业的负担，提高竞争力。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="fh5co-register"
      :style="{ backgroundImage: 'url(' + imgSrc_9 + ')' }"
    >
      <div class="overlay"></div>
      <div class="row">
        <div class="col-md-8 col-md-offset-2 animate-box">
          <div class="date-counter text-center">
            <h2>制典，为超过100家企业客户提供行业解决方案</h2>
            <h3></h3>
            <div class="simply-countdown simply-countdown-one">
              <!-- <div class="simply-section simply-days-section">
                <div>
                  <span class="simply-amount">{{ year }}</span>
                  <span class="simply-word">year</span>
                </div>
              </div>
              <div class="simply-section simply-days-section">
                <div>
                  <span class="simply-amount">{{ month }}</span>
                  <span class="simply-word">month</span>
                </div>
              </div> -->
              <div class="simply-section simply-days-section">
                <div>
                  <span class="simply-amount">{{ day }}</span>
                  <span class="simply-word">day</span>
                </div>
              </div>
              <div class="simply-section simply-hours-section">
                <div>
                  <span class="simply-amount">{{ hours }}</span>
                  <span class="simply-word">hours</span>
                </div>
              </div>
              <div class="simply-section simply-minutes-section">
                <div>
                  <span class="simply-amount">{{ minutes }}</span>
                  <span class="simply-word">minutes</span>
                </div>
              </div>
              <div class="simply-section simply-seconds-section">
                <div>
                  <span class="simply-amount">{{ seconds }}</span>
                  <span class="simply-word">seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="fh5co-gallery" class="margin_t50">
      <div class="container"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import countTo from "vue-count-to";
import { getImageListApi } from "@/api/index";
export default {
  components: {
    Head,
    Footer,
    countTo,
  },
  data() {
    return {
      //数字开始
      startVal: 0,
      //数字结束
      endVal: 5000,
      newDate: new Date(),
      timer: null,
      year: 0,
      month: 0,
      day: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      imgSrc: require("../static/images/img_bg_4.jpg"),
      imgSrc_1: require("../static/images/project-1.jpg"),
      imgSrc_2: require("../static/images/project_1.jpg"),
      imgSrc_3: require("../static/images/project_2.jpg"),
      imgSrc_4: require("../static/images/project_3.jpg"),
      imgSrc_5: require("../static/images/project_4.jpg"),
      imgSrc_6: require("../static/images/project_5.png"),
      imgSrc_7: require("../static/images/project_6.png"),
      imgSrc_8: require("../static/images/project_7.png"),
      imgSrc_9: require("../static/images/img_bg_2.jpg"),
      bannerdata: [],
      bannerSwitch:true,
      bannerappSwitch:false
    };
  },
  computed: {},
  created() {
    this.getImageList();
  },
  mounted() {
    var that = this; // 声明一个变量指向Vue实例this，保证作用域一致
    //显示当前日期时间
    this.timer = setInterval(() => {
      that.newDate = new Date(); // 修改数据date
      that.dateFormat(that.newDate);
      that.getDataTime();
    }, 1000);
    // this.changeAmount();
    window.addEventListener("resize", this.handleResize);
    this.handleResize()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    //获取当前时间
    dateFormat(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      let wk = date.getDay();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      // this.year = year;
      // this.month = month;
      // this.day = day;
      // this.hours = hours;
      // this.minutes = minutes;
      // this.seconds = seconds;
    },
    getImageList() {
      let params = {
        type_id: 1,
      };
      getImageListApi(params).then((res) => {
        console.log('res',res.code);
        if (res.code == 200) {
          let imageList = [];
          res.data.forEach((item, index) => {
            let img = item.image;
            let imgurl = item.url;
            let imgtext = item.description;
            if (img.includes("https://")) {
              imageList.push({
                img:img,
                url:imgurl,
                text:imgtext
              });
            } else if(img) {
              const image = this.$BaseUrl + item.image;
              imageList.push({
                img:image,
                url:imgurl,
                text:imgtext
              });
            }
          });
          this.bannerdata = imageList;
        }
      });
    },
    getDataTime() {
      // 定义开始日期和结束日期
      const startDate = new Date("2020-04-07 00:00:00");
      const endDate = new Date();
      // 计算时间差（毫秒）
      const timeDiff = endDate - startDate;
      // 转换为年、月、日、时、分、秒
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      this.day = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
    Software(){
      this.$router.push('/Software')
    },
    bannerLink(val){
      window.location.href = val.url;
    },
    handleResize(){
      // 获取视口宽度和高度
      const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      if(viewportWidth <= 390){
        this.bannerappSwitch = true,
        this.bannerSwitch = false
      } else if(viewportWidth >= 1024){
        this.bannerappSwitch = false,
        this.bannerSwitch = true
      }
    }
  },
};
</script>
<style scoped>
.el-carousel__container {
  position: relative;
}
.baaner_mb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-carousel__item h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  background: url(../static/images/img_bg_2.jpg) no-repeat bottom center;
  background-size: cover;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  background: url(../static/images/img_bg_1.jpg) no-repeat bottom center;
  background-size: cover;
}
@media (min-width: 1200px) {
  .col-lg-3 {
    width: 20%;
  }


}

@media (min-width: 1200px) {
  .container {
    width: 1300px;
  }
}

.imgText{
  position: absolute;
  top: 65%;
  left: 4.2%;
  color: #fff;
  font-size: 25px;
  width: 500px;
  height: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media screen and (min-width: 390px) and (max-width: 969px) {
    .imgText{
      width: 200px;
    } 
}
</style>
