<template>
  <div>
    <Head></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li>
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center slider-text">
                  <div class="slider-text-inner">
                    <h1 class="heading-section">联系我们</h1>
                    <p>
                      打造智慧园区，创建新型未来
                      <!-- <a href="http://www.cssmoban.com/" target="_blank" title="模板之家">模板之家</a> - Collect from <a href="http://www.cssmoban.com/" title="网页模板" target="_blank">网页模板</a> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>
    <div class="left-content" v-html="detail.content">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Head, Footer } from "../components/unit/index";
import {getConventionApi} from '@/api/index'
export default {
  components: {
    Head,
    Footer,
  },
  name: "Convention",
  data() {
    return {
      detail:{},
      id: 0
    }
  },
  mounted() {
    this.id =this.$route.query.id;
    this.getDetail(this.id)
  },
  methods:{
    getDetail(id){
      getConventionApi({id:id}).then(res => {
        if(res.code == 200) {
          this.detail = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
  .left-content{
    width:50%;
    margin-left: auto;
    margin-right: auto;
  }
</style>
<style lang="less" scoped>
.slides {
  width: 100%;
  height: 630px;
  li {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: rgb(255, 242, 0);
    background-image: url("../assets/images/img_bg_4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    .container {
      position: absolute;
      top: 40%;
      left: 10%;
      .heading-section {
        color: white;
      }
      p {
        color: white;
      }
    }
  }
}
</style>
