import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        menuList:[]
    },
    mutations:{
        updateData(state,data){
            state.menuList = data
            }
    },
    actions:{},
    modules:{}
})
export default store