<template>
    <div class="solution-div">
         <Head></Head>
        <!-- 背景图 -->
        <div class="bgr-div">
            <p>新闻动态</p>
        </div>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="branch-class"
    >
      <i class="iconfont icon-mianbao"></i>
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path:'/Skill'}">动态新闻</el-breadcrumb-item>
      <el-breadcrumb-item>方案详情</el-breadcrumb-item>
      </el-breadcrumb>
        <div class="content-div">
           <div class="left-div">
            <div class="container-div">
                <p class="title-div">{{title}}</p>
                <div class="time-div">
                    <i class="iconfont icon-shijian_o"></i>&nbsp;
                    <span>{{date}}</span>
                    &nbsp;&nbsp;&nbsp;
                     <i class="iconfont icon-guankan"></i>&nbsp;
                     <span>{{hits}}</span>
                      &nbsp;&nbsp;&nbsp;
                       <i class="iconfont icon-xinwen-laiyuan"></i>&nbsp;
                       <span>{{source}}</span>
                </div>
                <div class="left-content" v-html="content">
                </div>
            </div>
           </div>
           <div class="right-div">
            <div class="container-div">
                <p class="title-div">更多新闻</p>
                <div class="more-div">
                    <div class="part-div" v-for="(item,index) in newList" :key="index" :class="{'active':curActiveId==item.id}" @click="changeNews(item)">
                    <div class="word-div">
                        <div class="title-t">{{item.title}}</div>
                        <div class="text-div">{{item.summary}}</div>
                        <div class="time-div">{{item.create_time}}</div>
                    </div>
                    </div>
                </div>
            </div>
           </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { Head, Footer } from "../components/unit/index";
import {getNewsDetailsApi,getListApi,getHotListApi} from '@/api/index'
export default {
    components: {
    Head,
    Footer
  },
    data(){
        return {
           title:"",
           create_time:"",
           date:"",
           content:'',
           newList:[],
           imgSrc:"",
           hits:"",
           source:"",
           curActiveId:"",
           id:"",
           pageSize:6,
           cate_id:"",
           currentPage:1
        }
    },
    mounted(){
        // console.log(this.$route.query.activeName)
        this.id=this.$route.query.id
        if (this.$route.query.id) {    
            this.curActiveId = this.$route.query.id;
        }
        if(this.$route.query.activeName=="全部"){
            //热门新闻列表
            this.getHotListMethod()
        }else if(this.$route.query.activeName=="企业新闻"){
            this.cate_id=5
            this.getNewList()
        }else if(this.$route.query.activeName=="行业资讯"){
            this.cate_id=6
            this.getNewList()
        }
        // 动态新闻详情
        this.getNewDetail(this.id)
      },
    methods:{
        changeNews(item){
            this.id=item.id
            this.curActiveId=item.id
            this.getNewDetail(this.id)
        },
    //动态新闻详情
   getNewDetail(id){
    getNewsDetailsApi(id).then(res=>{
        if(res.code==200){
          this.title=res.data.title
          this.date=res.data.create_time
          this.hits=res.data.hits
          this.source=res.data.source
          this.content=res.data.content
        }
    })
   },

   //动态新闻列表
   getNewList(){
    const data={
        cate_id:this.cate_id,
        page:this.pageSize,
        limit:this.currentPage
      }
    getListApi(data).then(res=>{
        if(res.code==200){
            console.log(res,"---res---");
            this.newList=res.data.data
        }
    })
   },
     //热门新闻接口
    getHotListMethod(){
      const pageSize=this.pageSize
      getHotListApi(pageSize).then(res=>{
        if(res.code==200){
          this.newList=res.data.data
        }
      })
    },
    }
}
</script>

<style scoped lang="less">
.iconfont.icon-mianbao{
    font-size: 40px;
    position: absolute;
    left: -4%;
    top: -91%;
}
.part-div{
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 10px;
}
.part-div.active{
    background: rgb(221, 219, 219);
}
.left-content{
    width: 100%;
    section{
         width: 100%;     
    }
    p{
    width: 100%; 
    
    }
   
}
 img{
        display: block;
        width: 400px !important;
     }
.solution-div{
    width: 100%;
    position: relative;
    .branch-class{
    margin-bottom: 20px;
    margin-left: 10%;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: #5481ba !important;
    }
    }
    .bgr-div{
        width: 100%;
        height: 350px;
        background-image: url(../assets/images/project_3.jpg);
        // background-position:20%, 50%;
        background-size: 100%;
        text-align: center;
        overflow: hidden;
        margin-bottom: 100px;
        p{
            color: white;
            font-weight: bolder;
            line-height: 350px;
            font-size: 30px;
            height: 350px;
            
        }
    }
    .title-p{
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: black;
        margin-top: 35px;
    }
    .text-p{
        font-size: 16px;
        text-align: center;
        font-weight: normal;
        color: #666;
        margin-bottom: 40px;
    }
    .content-div{  
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        border-top: 1px solid rgb(198, 197, 197);
        overflow: hidden;
    }
    .left-div{
        width: 67%;
        border-right: 1px solid rgb(198, 197, 197);
        text-align: left;
        .container-div{
            width: 85%;
            margin-left: 10%;
            .title-div{
                width: 100%;
                font-weight: 600;
                font-size: 20px;
                text-align: center;
                color: black;
                margin: 15px auto;
            }
            .time-div{
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgb(198, 197, 197);
                margin-bottom: 20px;
            }
        }
        .left-content{
           ::v-deep img{
                width: 100%;
            }
        }
    }
    .right-div{
        width: 33%;
        .container-div{
            width: 65%;
            margin-left: 10px;
            .title-div{
                height: 60px;
                width: 100%;
                font-weight: 600;
                font-size: 18px;
                text-align: center;
                color: black;
                line-height: 100px;
                // margin-top: 40px;
            }
            .more-div{
                width: 300px;
                border-radius: 3px;
                background-color:#F3F3F3;
                overflow: hidden;
                margin-bottom: 15px;
                .img-div{
                    width: 100px;
                    height: 100px;
                    img{
                        display: block;
                        width: 100px;
                        height: 100px;
                    }
                }
                .word-div{
                    margin-left: 10px;
                    text-align: left;
                .title-t{
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 10px;
                }
                .text-div{
                    font-size: 13px;
                    color: #999999;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
                .time-div{
                    font-size: 13px;
                    color: #999999;
                }
                }
            }
        }
    }
}
</style>