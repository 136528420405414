<template>
  <div>
    <footer
      id="fh5co-footer"
      role="contentinfo"
      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-3 fh5co-widget">
            <img :src="logoSrc" />
            <p>
              公司总部设在南京，公司集聚了资深的行业技术与业务专家技术人员占比超过80%，且公司拥有多项软著和专利。
            </p>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <h3>解决方案</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li v-for="(item,index) in schemeList" :key="index" class="solution-class" @click="solutionClick(item)">
                    {{item.cate_name}}
              </li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <h3>新闻动态</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li v-for="(item,index) in classifyList" :key="index" class="solution-class" @click="newClick(item)">
                    {{item.cate_name}}
              </li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <h3>走进制典</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li @click="aboutClick()"  class="solution-class">公司介绍</li>
              <li @click="aboutClick()"  class="solution-class">企业资质</li>
              <li @click="aboutClick()"  class="solution-class">合作单位</li>
              <li @click="Software()"  class="solution-class">软件著作</li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <h3>联系我们</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li @click="contactClick()"  class="solution-class">公司地址</li>
              <li @click="contactClick()"  class="solution-class">联系电话</li>
              <li @click="contactClick()"  class="solution-class">企业邮箱</li>
              <li @click="contactClick()"  class="solution-class">公司官网</li>
            </ul>
          </div>
        </div>

        <div class="row copyright">
          <div class="col-md-12 text-center">
            <p>
              <small class="block" style="height: 20px">
                Copyright ©2020-{{ year }}
                NjZhidian.Co.,Ltd.All Rights Reserved&nbsp;&nbsp;-&nbsp;&nbsp;南京制典网络科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;-&nbsp;&nbsp;
                <a target="_blank" href="https://beian.miit.gov.cn/" style="height: 20px">苏ICP备20029342号-1</a>｜
                <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=32011302321871" style="height: 20px">
                <img src="../../assets/beian_icon.png" alt="" style="display: inline-block;height: 14px;margin-bottom: 3px">  苏公网安备32011302321871
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </footer>
    <div class="gototop js-top">
      <a href="#" class="js-gotop"><i class="icon-up"></i></a>
    </div>
  </div>
</template>
<script>
import { getSolutionApi,trpeListApi } from "@/api/index";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {
      logoSrc: require("../../static/images/logo.png"),
      imgSrc: require("../../static/images/img_bg_4.jpg"),
      schemeList:[],
      classifyList:[],
      cate_name:"",
      year:''
    };
  },
  mounted(){
    this.dateFormat()
    this.getSolution()
    this.trpeListMethod()
  },
  methods:{
    getSolution(){
      getSolutionApi().then(res=>{
        if(res.code==200){
          console.log(res,"---res---");
          this.schemeList=res.data
        }
      })
    },
    solutionClick(val){
      const id=val.id
      this.$router.push({path:"/SolutionDetails",query:{id:id}})
    },
    trpeListMethod(){
      trpeListApi().then(res=>{
        if(res.code==200){
          this.classifyList=res.data
        }
      })
    },
    newClick(val){
      this.cate_name=val.cate_name
      this.$router.push({path:"/Skill",query:{cate_name:this.cate_name}})
    },
    contactClick(){
      this.$router.push({path:"/Contact"})
    },
    aboutClick(){
      this.$router.push({path:"/About"})
    },
    Software(){
      this.$router.push({path:"/Software"})
    },
    dateFormat(){
      let date = new Date();
      let year = date.getFullYear();
      this.year = year;
    }
  }
};
</script>
<style lang="less" scoped>
.solution-class{
  color: rgba(255, 255, 255, 0.5);
  text-decoration:none;
  cursor: pointer;
}
.fh5co-footer-ul{
  width: 60px;
}
</style>
