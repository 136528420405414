<template>
  <div>
    <Head></Head>
    <el-carousel height="630px">
      <el-carousel-item>
        <div class="baaner_mb">
          <div>
            <h1 class="small">{{ bannerdata.title }}</h1>
            <h2 class="small" v-html="bannerdata.content"></h2>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div id="fh5co-about">
      <div class="container">
        <div class="col-md-6 animate-box">
          <h2>公司介绍</h2>
          <p>
            南京制典网络有限公司，坐落于六朝古都南京市栖霞区兴智科技注册资金1000万，
            软著和专利10余项，主要从事物联网、大数据、云计算、AI人工智能等新型网络技术研发工作，
            从2021年起一直被评为科技型企业，研发人员占比80%，已通过IOS9001质量管理体系认证，
            属于南京邮电大学设计院孵化企业。
          </p>
          <p>
            南京制典互联网科技为客户提供领先的行业解决方案和系统
            集成服务。公司借助现代数字通信、互联网、移动通信及物联网技术
            ，通过云计算、大数据等方式为客户提供全方位无缝、快捷、安全和高效的互联网服务解决方案。
          </p>
          <p>
            公司旗下产品包括：智慧园区系统、智慧校园系统、智慧小区、智能水产养殖系统、智能缴费系统等等。
          </p>
        </div>
        <div class="col-md-6">
          <video
            src="video/moive.mp4"
            :poster="imgSrc"
            controls="controls"
            class="video-box"
          ></video>
        </div>
      </div>
    </div>

    <div
      id="fh5co-counter"
      class="fh5co-counters"
      :style="{ backgroundImage: 'url(' + imgSrc_2 + ')' }"
      data-stellar-background-ratio="0.5"
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="row">
              <div class="col-md-3 col-sm-6 text-center animate-box">
                <span class="icon"><i class="iconfont icon-diannao"></i></span>
                <span class="fh5co-counter-label">技术能力</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center animate-box">
                <span class="icon"><i class="iconfont icon-anquan"></i></span>
                <span class="fh5co-counter-label">安全能力</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center animate-box">
                <span class="icon"><i class="iconfont icon-shuji"></i></span>
                <span class="fh5co-counter-label">服务能力</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center animate-box">
                <span class="icon"><i class="iconfont icon-chuangxinchanpin"></i></span>
                <span class="fh5co-counter-label">创新能力</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="fh5co-course-categories">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>企业资质</h2>
            <p>
              制典一路走来，共获取了多项专项认证，是具有完备技术能力的互联网科技公司。
            </p>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-md-4 col-sm-6 text-center animate-box">-->
<!--            <div class="services">-->
<!--              <span class="icon">-->
<!--                <i class="iconfont icon-zhiliangguanlixitong" style="font-size:34px;"></i>-->
<!--              </span>-->
<!--              <div class="desc">-->
<!--                <h3><a href="#">ISO9001质量管理体系</a></h3>-->
<!--                <p>-->
<!--                  ISO9001用于证实组织具有提供满足顾客要求和适用法规要求的产品的能力，增进顾客满意度。-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        <div class="row animate-box authentication">
          <div class="col-md-4 col-sm-6 text-center animate-box center">
            <div class="services">
              <span class="icon">
                <i class="iconfont icon-xingzhuang" style="font-size:32px;"></i>
              </span>
              <div class="desc">
                <h3><a href="#">ISO9001质量管理体系</a></h3>
                <p>
                  ISO9001用于证实组织具有提供满足顾客要求和适用法规要求的产品的能力，增进顾客满意度。
                </p>
              </div>
            </div>
          </div>
        </div>

<!--          <div class="col-md-4 col-sm-6 text-center animate-box">-->
<!--            <div class="services">-->
<!--              <span class="icon">-->
<!--                <i class="iconfont icon-fuwuguanli_o" style="font-size:40px;"></i>-->
<!--              </span>-->
<!--              <div class="desc">-->
<!--                <h3><a href="#">ISO20000IT服务体系</a></h3>-->
<!--                <p>-->
<!--                  ISO20000信息技术服务管理体系标准代表了被广泛认可的评估IT服务管理流程的原则的基础。-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <!--<div class="col-md-3 col-sm-6 text-center animate-box">
					<div class="services">
						<span class="icon">
							<i class="icon-lab2"></i>
						</span>
						<div class="desc">
							<h3><a href="#">Science &amp; Technology</a></h3>
							<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 text-center animate-box">
					<div class="services">
						<span class="icon">
							<i class="icon-photo"></i>
						</span>
						<div class="desc">
							<h3><a href="#">Art &amp; Media</a></h3>
							<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 text-center animate-box">
					<div class="services">
						<span class="icon">
							<i class="icon-home-outline"></i>
						</span>
						<div class="desc">
							<h3><a href="#">Real Estate</a></h3>
							<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 text-center animate-box">
					<div class="services">
						<span class="icon">
							<i class="icon-bubble3"></i>
						</span>
						<div class="desc">
							<h3><a href="#">Language</a></h3>
							<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 text-center animate-box">
					<div class="services">
						<span class="icon">
							<i class="icon-world"></i>
						</span>
						<div class="desc">
							<h3><a href="#">Web &amp; Programming</a></h3>
							<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
						</div>
					</div>
				</div>-->
<!--        </div>-->
      </div>
    </div>

    <div
      id="fh5co-register"
      :style="{ backgroundImage: 'url(' + imgSrc_3 + ')' }"
    >
      <div class="overlay"></div>
      <div class="row">
        <div class="col-md-8 col-md-offset-2 animate-box">
          <div class="img-class">
            <div v-for="(item,index) in markList" :key="index" >
              <a :href="item.url"><img :src="item.logo" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div id="fh5co-gallery" class="fh5co-bg-section">
		<div class="row text-center">
			<h2><span>Instagram Gallery</span></h2>
		</div>
		<div class="row">
			<div class="col-md-3 col-padded">
				<a href="#" class="gallery" style="background-image: url(images/project-5.jpg);"></a>
			</div>
			<div class="col-md-3 col-padded">
				<a href="#" class="gallery" style="background-image: url(images/project-2.jpg);"></a>
			</div>
			<div class="col-md-3 col-padded">
				<a href="#" class="gallery" style="background-image: url(images/project-3.jpg);"></a>
			</div>
			<div class="col-md-3 col-padded">
				<a href="#" class="gallery" style="background-image: url(images/project-4.jpg);"></a>
			</div>
		</div>
	</div>-->
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import {AboutApi,linkApi} from '@/api/index'
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      imgSrc: require("../static/images/img_bg_gywm.jpg"),
      imgSrc_1: require("../static/images/fengmian.jpeg"),
      imgSrc_2: require("../static/images/img_bg_gywm.jpg"),
      imgSrc_3: require("../static/images/img_bg_5.jpg"),
      bannerdata: {},
      markList:[]
    };
  },
  mounted() {
    this.getAbout()
    this.getLink()
  },
  methods:{
    //banner轮播图
    getAbout(){
      AboutApi().then(res=>{
        if(res.code==200){
          this.bannerdata=res.data
        }
      })
    },

    //友情链接
    getLink(){
      linkApi().then(res=>{
        if(res.code==200){
          const logoList=[]
          res.data.forEach((item,index)=>{
            const mark={
              logo:"",
              url:""
            }
            let logo = item.logo;
            if(logo.includes('https://')){
              mark.logo= logo
            }else{
              mark.logo= this.$BaseUrl+logo
            }

            mark.url=item.url
            logoList.push(mark)
          })
          this.markList=logoList
          console.log(this.markList,"---markList---");
        }
      })
    }
  }


};
</script>
<style scoped lang="less">
.el-carousel__container {
  position: relative;
}
.baaner_mb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-carousel__item h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
}
.el-carousel__item h2 {
  color: #fff;
  padding: 40px 80px;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  background: url(../static/images/img_bg_gywm.jpg) no-repeat bottom center;
  background-size: cover;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  background: url(../static/images/img_bg_gywm.jpg) no-repeat bottom center;
  background-size: cover;
}
.img-class{
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img{
    width: 300px;
    height:80px;
    margin:10px;
  }
}
.authentication{
  display: flex;
  justify-content: center;
}
</style>
