<template>
  <div>
    <Head></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li>
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center slider-text">
                  <div class="slider-text-inner">
                    <h1 class="heading-section">最新资讯</h1>
                    <h3>
                      what we're
                      doing&nbsp;?&nbsp;&nbsp;·&nbsp;&nbsp;最近我们都在干什么？
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>

    <div id="fh5co-pricing" class="fh5co-bg-section">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>动态新闻</h2>
            <p>我们已经走在彼此相遇的路上，等待推门而入。</p>
          </div>
        </div>
        <div class="row">
          <div class="pricing pricing--rabten">
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
              class="tab-class"
            >
              <el-tab-pane label="全部" name="全部">
                <div
                  class="col-md-4 animate-box"
                  v-for="(item, index) in newList"
                  :key="index"
                >
                  <div class="card-div" @click="clicknews(item)">
                    <div class="img-div">
                      <img :src="item.image" alt="" />
                    </div>
                    <div class="text-div">
                      <h4>{{ item.title }}</h4>
                      <div class="word-d">
                        {{ item.summary }}
                      </div>
                    </div>
                    <div class="time-div">
                      <div class="year-div">{{ item.date }}</div>
                      <div class="hour-div">{{ item.time }}</div>
                      <div class="radius-div">详情</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                :label="item.cate_name"
                :name="item.cate_name"
                v-for="(item, index) in newClassify"
                :key="index"
              >
                <div
                  class="col-md-4 animate-box"
                  v-for="(item, index) in classifyList"
                  :key="index"
                >
                  <div class="card-div" @click="clicknews(item)">
                    <div class="img-div">
                      <img :src="item.image" alt="" />
                    </div>
                    <div class="text-div">
                      <h4>{{ item.title }}</h4>
                      <div class="word-d">
                        {{ item.summary }}
                      </div>
                    </div>
                    <div class="time-div">
                      <div class="year-div">{{ item.date }}</div>
                      <div class="radius-div">详情</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page="currentPage"
              @size-change="changeSizeClick"
              @current-change="currentPageClick"
              style="width: 100%; text-align: center"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from '../components/unit/index'
import { getListApi, trpeListApi, getHotListApi } from '@/api/index'
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      imgSrc: require('../static/images/img_bg_gywm.jpg'),
      imgSrc_1: require('../static/images/fengmian.jpeg'),
      bannerdata: {},
      newList: [],
      newClassify: [],
      activeName: '全部',
      classifyList: [],
      curTabName: '',
      total: 0,
      pageSize: 6,
      cate_id: '',
      currentPage: 1,
      jumpName: '',
    }
  },
  mounted() {
    if (this.$route.query.cate_name !== undefined) {
      this.activeName = this.$route.query.cate_name
      if (this.activeName == '企业新闻') {
        this.cate_id = 5
        this.getListMethod()
      } else if (this.activeName == '行业资讯') {
        this.cate_id = 6
        this.getListMethod()
      }
    } else if (this.$route.query.cate_name == undefined) {
      this.activeName == '全部'
    }
    this.getTrpeList()
    this.getHotListMethod()
  },
  watch: {
    '$route.query.cate_name': {
      handler(newVal, oldVal) {
        this.activeName = this.$route.query.cate_name
        console.log(this.activeName)
        if (this.activeName == '企业新闻') {
          this.cate_id = 5
          this.getListMethod()
        } else if (this.activeName == '行业资讯') {
          this.cate_id = 6
          this.getListMethod()
        }
      },
    },
  },
  methods: {
    //热门新闻接口
    getHotListMethod() {
      const data = {
        cates: '5,6',
        limit: this.pageSize,
        page: this.currentPage,
      }
      getHotListApi(data).then(res => {
        if (res.code == 200) {
          this.newList = res.data.data
          this.total = this.newList.length
          this.newList.forEach(item => {
            let img = item.image
            if (img.includes('https://')) {
              item.image = img
            } else {
              item.image = this.$BaseUrl + item.image
            }
            item.date = item.create_time.split(' ')[0]
          })
        }
      })
    },
    //新闻列表接口
    getListMethod() {
      const data = {
        cate_id: this.cate_id,
        limit: this.pageSize,
        page: this.currentPage,
      }
      getListApi(data).then(res => {
        if (res.code == 200) {
          this.total = res.data.total
          this.classifyList = res.data.data
          this.classifyList.forEach((item, index) => {
            let img = item.image
            if (img.includes('https://')) {
              item.image = img
            } else {
              item.image = this.$BaseUrl + item.image
            }
            item.date = item.create_time.split(' ')[0]
          })
        }
        // console.log(res,"这是新闻列表接口");
      })
    },

    // async getNewList() {
    //   let res = await getListApi();
    //   if (res.code == 200) {
    //     this.newList = res.data.data ? res.data.data : [];
    //     this.newList.forEach((item) => {
    //       item.image = "https://www2.njzhidian.com" + item.image;
    //       item.date = item.create_time.split(" ")[0];
    //       item.time = item.create_time.split(" ")[1];
    //     });
    //   }
    //   if (this.activeName) {
    //     this.classifyList = this.newList.filter(
    //       (item) => item.cate_name == this.activeName
    //     );
    //   }else{
    //       this.activeName="全部"
    //       this.classifyList = this.newList //默认展示全部
    //   }
    //     console.log("this.newList", this.newList);
    //     console.log("this.classifyList",this.classifyList);
    // },

    //新闻分类事件
    getTrpeList() {
      trpeListApi().then(res => {
        if (res.code == 200) {
          this.newClassify = res.data
        }
      })
    },

    //新闻点击事件
    clicknews(item) {
      console.log(item, '新闻点击事件')
      const id = item.id
      const activeName = this.activeName
      this.$router.push({
        path: '/Newsdetails',
        query: { id: id, activeName: activeName },
      })
    },

    //标签页点击事件
    handleClick(tab, event) {
      console.log('handleClick', tab.name)
      if (tab.name == '企业新闻') {
        this.cate_id = 5
        this.currentPage = 1
        this.getListMethod()
      } else if (tab.name == '行业资讯') {
        this.cate_id = 6
        this.currentPage = 1
        this.getListMethod()
      } else if (tab.name == '全部') {
        this.currentPage = 1
        this.getHotListMethod()
      }
      this.curTabName = tab.name
    },
    changeSizeClick(val) {
      console.log(val, '---changeSizeClick---')
    },
    currentPageClick(val) {
      this.currentPage = val
      this.getListMethod()
    },
  },
}
</script>
<style lang="less" scoped>
.tab-class {
  width: 100%;
  ::v-deep .el-tabs__active-bar {
    width: 383px !important;
  }
  ::v-deep .el-tabs__item {
    width: 383px !important;
    text-align: center;
  }
}
.iconfont {
  font-size: 20px;
}
.slides {
  width: 100%;
  height: 630px;
  li {
    width: 100%;
    height: 630px;
    background-image: url(../assets/images/project_7.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .container {
      position: absolute;
      top: 40%;
      left: 10%;
      .heading-section {
        color: white;
      }
      h3 {
        color: white;
      }
    }
  }
}
.card-div {
  width: 377px;
  border: 1px solid rgb(222, 222, 222);
  margin: 0px 15px 20px 0px;
  cursor: pointer;
  .img-div {
    width: 376px;
    height: 222px;
    img {
      display: block;
      width: 376px;
      height: 222px;
    }
  }
}
.text-div {
  width: 376px;
  height: 300px;
  font-size: 16px;
  color: #000000;
  line-height: 58px;
  text-align: center;
  padding: 10px;
  h4 {
    margin-top: 15px;
  }
  .word-d {
    line-height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    overflow: hidden;
    text-align: left;
  }
}
.time-div {
  width: 376px;
  height: 100px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  .year-div {
    font-size: 30px;
    color: #707070;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-left: 15px;
    margin-top: 20px;
  }
  .hour-div {
    font-size: 20px;
    color: #707070;
    margin-left: 15px;
  }
  .radius-div {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50% 50%;
    font-size: 12px;
    cursor: pointer;
    color: #b3b1b1;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 20px;
    top: 30px;
  }
}
/* Common styles */
</style>
