<template>
  <div>
    <Head></Head>
    服务理念
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
export default {
  components: {
    Head,
    Footer,
  },
};
</script>
<style lang="less" scoped></style>
