import request from '@/utils/request'


//获取解决方案
export function getSolutionApi (data) {
    return request({
      url: '/api/index/getSolution',
      method: 'get',
      params: data
    })
  }

//友情链接
export function linkApi () {
    return request({
      url: '/api/index/link',
      method: 'get',
      params: ''
    })
  }

//获取图片列表
export function getImageListApi (data) {
    return request({
      url: '/api/index/getImageList',
      method: 'post',
      data: data
    })
  }

//首页关于我们
export function AboutApi () {
    return request({
      url: '/api/index/about',
      method: 'post',
      data:''
    })
  }

//新闻列表
export function getListApi (data) {
    return request({
      url: '/api/news/getList',
      method: 'post',
      data:data
    })
  }

//新闻分类
export function trpeListApi () {
    return request({
      url: '/api/news/trpeList',
      method: 'get',
      params:''
    })
  }

//动态新闻详情
export function getNewsDetailsApi (id) {
    return request({
      url: `/api/news/detail/${id}`,
      method: 'get',
    })
  }

//动态新闻列表
export function getNewListApi () {
    return request({
      url:"/api/news/getList",
      method: 'post',
    })
  }

//解决方案详情
export function getsolutionDetailApi(id) {
    return request({
      url:`/api/index/solutionDetail/${id}`,
      method: 'get',
    })
  }

//导航栏菜单
export function menuApi(id) {
    return request({
      url:"/api/index/menu",
      method: 'get',
    })
  }

//热门新闻
export function getHotListApi(pageSize) {
  return request({
    url:"/api/news/getHotList",
    method: 'post',
    data:pageSize
  })
}

//获取软著列表
export function getWorksListApi(param) {
    return request({
        url:"/api/works/getList",
        method: 'post',
        data: param
    })
}

//获取平台自律公约
export function getConventionApi(param) {
    return request({
        url:"/api/convention/detail",
        method: 'post',
        data: param
    })
}
