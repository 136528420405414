<template>
  <div class="solution-div">
    <Head></Head>
    <!-- 背景图 -->
    <div class="bgr-div">
      <p>解决方案</p>
    </div>
    <p class="title-p">专业专注，精益求精</p>
    <p class="text-p">为行业持续提供优质的解决方案</p>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="branch-class"
    >
      <i class="iconfont icon-mianbao"></i>
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path:'/Scheme'}">解决方案</el-breadcrumb-item>
      <el-breadcrumb-item>方案详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-div">
      <div class="left-div">
        <div class="container-div">
          <p class="title-div">{{title}}</p>
          <div class="time-div">
            <i class="iconfont icon-shijian_o"></i>
            &nbsp;
            <span>{{create_time}}</span>
          </div>
          <div
            class="left-content"
            v-html="content"
          >
          </div>
        </div>
      </div>
      <div class="right-div">
        <div class="container-div">
          <p class="title-div">更多方案</p>
          <div class="more-div">
            <div
              class="part-div"
              v-for="(item,index) in newList.slice(0, showNum)"
              :key="index"
              @click="changeDetails(item)"
              :class="{'active':curActiveId==item.id}"
            >
              <div class="img-div">
                <img
                  :src="item.image"
                  alt=""
                >
              </div>
              <div class="word-div">
                <div class="title-t">{{item.create_time}}</div>
                <div class="text-div">{{item.summary}}</div>
                <div class="time-div">{{item.create_time}}</div>
              </div>
            </div>
            <div class="button-div" v-show="btnSwitch">
              <el-button type="text" @click="showMore" >查看更多</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Head, Footer } from "../components/unit/index";
import {getsolutionDetailApi,getSolutionApi,getHotListApi} from "@/api/index";
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      curActiveId: "", //当前选中标签Id
      title: "",
      create_time: "",
      content: "",
      newList: [],
      imgSrc: "",
      id: "",
      showNum: 3,
      length:0,
      btnSwitch:true
    };
  },
  mounted() {
    console.log("path----", this.$route.query.id);
       this.id = this.$route.query.id;
    if (this.id) {
      this.curActiveId = this.id
      this.getsolutionDetail(this.id)
    }
 
    //解决方案列表
    this.getSolutionMethod()
    
  },
  watch:{
    '$route.query.id':{
      handler(newVal,oldVal){
        console.log("id",newVal)
        this.id=newVal
        this.curActiveId = newVal
        this.getsolutionDetail(this.id)
      }
    }
  },
  methods: {
    changeDetails(item) {
      this.id = item.id;
      this.curActiveId =item.id;
      this.getsolutionDetail(this.id)
    },
    getsolutionDetail(id){
      getsolutionDetailApi(id).then(res=>{
        if(res.code==200){     
            this.title = res.data.title;
            this.create_time = res.data.create_time;
            this.content = res.data.content;
        }
      })
    },
    //解决方案列表
    getSolutionMethod(){
      getSolutionApi().then(res=>{
        if(res.code==200){
          console.log(res,"---res---")
          this.newList = res.data
          this.length =this.newList.length
           this.newList.forEach((item, index) => {
             let img = item.image;
             if(img.includes('https://')){
                item.image = img;
             } else {
                item.image = this.$BaseUrl + item.image;
             }
          });
        }
      })
    },
    showMore() {
      if (this.showNum < this.length) {
        this.showNum += 3;
        if (this.showNum > this.length) {
          this.showNum = this.length;
          this.btnSwitch = false;
        }
      } else {
          this.btnSwitch = false;
      }
    }
  },
};
</script>

<style scoped lang="less">
.iconfont.icon-mianbao{
  font-size: 40px;
  position: absolute;
  left: -4%;
  top: -17px;
}
.solution-div{
  position: relative;
}
.part-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 15px;
  cursor: pointer;
}
.left-content {
  width: 100%;
  section {
    width: 100%;
  }
  p {
    width: 100%;
  }
}
img {
  display: block;
  width: 400px !important;
}
.solution-div {
  width: 100%;
  .bgr-div {
    width: 100%;
    height: 350px;
    background-image: url(../assets/images/image1.jpg);
    background-position: 20%, 50%;
    background-size: 80%;
    text-align: center;
    overflow: hidden;
    p {
      color: white;
      font-weight: bolder;
      line-height: 350px;
      font-size: 30px;
      height: 350px;
    }
  }
  .branch-class {
    margin-bottom: 20px;
    margin-left: 10%;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: #5481ba !important;
    }
  }
  .title-p {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: black;
    margin-top: 35px;
  }
  .text-p {
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    color: #666;
    margin-bottom: 40px;
  }
  .content-div {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgb(198, 197, 197);
    overflow: hidden;
  }
  .left-div {
    width: 67%;
    border-right: 1px solid rgb(198, 197, 197);
    text-align: center;
    .container-div {
      width: 85%;
      margin-left: 10%;
      .title-div {
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: black;
        margin: 15px auto;
      }
      .time-div {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgb(198, 197, 197);
        margin-bottom: 20px;
      }
    }
    .left-content {
      ::v-deep img {
        width: 100% !important;
      }
    }
  }
  .right-div {
    width: 33%;
    .container-div {
      width: 83%;
      height: 520px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-left: 10px;
      .title-div {
        height: 60px;
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: black;
        line-height: 100px;
        // margin-top: 40px;
      }
      .more-div {
        width: 370px;
        border-radius: 3px;
        background-color: #f3f3f3;
        overflow: hidden;
        margin-bottom: 15px;
        .img-div {
          width: 100px;
          height: 100px;
          img {
            display: block;
            width: 100px !important;
            height: 100px;
          }
        }
        .word-div {
          width: 223px;
          margin-left: 10px;
          text-align: center;
          .title-t {
            font-size: 14px;
            color: #333;
            margin-bottom: 10px;
          }
          .text-div {
            font-size: 13px;
            color: #999999;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .time-div {
            font-size: 13px;
            color: #999999;
          }
        }
        .part-div.active {
          background: rgb(221, 219, 219);
        }
      }
    }
  }
  .button-div{
    text-align: center;
  }
}
</style>