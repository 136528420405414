import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import "./assets/css/bootstrap.css"
import "./assets/css/style.css"
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/fonts/iconfont.css'
import axios from 'axios'
import store from './store/index'
import VuePreview from 'vue-preview'

Vue.prototype.$http = axios
// Vue.prototype.$BaseUrl = "https://www.njzhidian.com/dist"
Vue.prototype.$BaseUrl = "https://www.njzhidian.com"



Vue.use(ElementUI)
// Vue.use(VuePreview)
Vue.use(VuePreview, {
  captionEl: false,
  fullscreenEl: false,
  shareEl: false,
  tapToClose: true,
  tapToToggleControls: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
