<template>
  <div id="Head">
    <el-button
      class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white"
      @click="drawer = true"
      type="primary"
      style="margin-left: 25px background:transparent"
    >
      <i></i>
    </el-button>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :direction="direction"
      :with-header="false"
      :show-close="true"
      :append-to-body="true"
    >
      <div id="fh5co-offcanvas">
        <ul>
          <li
            :class="Cindex == index ? 'active' : ''"
            v-for="(item, index) in menudata"
            :key="item.id"
            @click="highlight(index)"
          >
            <router-link
              :to="{
                name: item.cate_folder,
                params: { id: index },
              }"
              >{{ item.cate_name }}</router-link
            >
          </li>
        </ul>
      </div>
    </el-drawer>
    <nav class="fh5co-nav" role="navigation">
      <div class="top-menu">
        <div class="container">
          <div class="row">
            <div class="col-xs-2">
              <!--logo修改-->
              <div id="fh5co-logo">
                <a href="index.html"><img :src="logoSrc" /></a>
              </div>
            </div>
            <div class="col-xs-10 text-right menu-1">
              <ul>
                <!-- <li class="homePage" @click="jumpPage()">首页</li> -->
                <li
                  :class="Cindex == index ? 'active' : ''"
                  v-for="(item, index) in menudata"
                  :key="item.id"
                  @click="highlight(index)"
                >
                  <el-dropdown>
                    <router-link
                      :to="{
                        name: item.cate_folder,
                        params: { id: index },
                      }"
                      >{{ item.cate_name }}</router-link
                    >
                    <el-dropdown-menu slot="dropdown" v-show="item.child">
                      <el-dropdown-item
                        v-for="itemss in item.child"
                        :key="itemss.cate_name"
                        @click.native="listClick(itemss)"
                        v-show="itemss"
                        >{{ itemss.cate_name }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
                <!-- <el-dropdown trigger="click">
                    <el-button type="primary" size="small">
                      关注微信公众号<i class="el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown" class="el-dropdown-img">
                      <div>
                        <img
                          src="../../static/images/code.jpg"
                          alt=""
                          width="150"
                          height="150"
                        />
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                <el-button @click="btn()" type="primary" size="small"
                  >关注微信公众号</el-button
                >
                <div v-show="codeSwitch" class="codeimg">
                  <img src="../../static/images/code.jpg" alt="" class="img" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { menuApi } from "@/api/index";
export default {
  name: "Head",
  props: {
    msg: String,
  },
  data() {
    return {
      Cindex: null,
      logoSrc: require("../../static/images/logo.png"),
      menudata: [],
      drawer: false,
      direction: "rtl",
      codeSwitch: false,
    };
  },
  methods: {
    //获取菜单
    highlight(index) {
      this.Cindex = index;
      this.Cindex = this.$route.params.id;
    },
    //点击事件
    listClick(val) {
      console.log(val, "---listClick---");
      if (val.pid == 4) {
        //跳转daodao
        const cate_name = val.cate_name;
        this.$router.push({ path: "/Skill", query: { cate_name: cate_name } });
      } else if (val.pid == 8) {
        const id = val.id;
        this.$router.push({ path: "/SolutionDetails", query: { id: id } });
      } else if (val.pid == 1) {
        const id = val.id;
        this.$router.push({ path: "/Software", query: { id: id } });
      }else {
        const id = val.id;
        const cate_folder = "/"+val.cate_folder;
        this.$router.push({ path: cate_folder , query: {id : id}})
      }
    },
    getMeau() {
      if (this.$store.state.menuList.length > 0) {
        this.menudata = this.$store.state.menuList;
      } else {
        menuApi().then((res) => {
          if (res.code == 200) {
            this.menudata = res.data;
            this.menudata.unshift({ cate_name: "首页", cate_folder: "home" });
            this.$store.commit("updateData", this.menudata);
          }
        });
      }
    },
    //首页进行跳转
    jumpPage() {
      this.$router.push({ path: "/home" });
    },
    btn() {
      this.codeSwitch = !this.codeSwitch;
    },
  },
  mounted() {
    this.getMeau();
    var that = this;
    if (
      Object.prototype.toString.call(this.$route.params.id) !=
      "[object Undefined]"
    ) {
      this.Cindex = this.$route.params.id;
    }
    console.log(this.Cindex, this.$route.params.id);
  },
};
</script>
<style scoped>
.homePage {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  margin-right: 15px !important;
  cursor: pointer;
}
.fh5co-nav {
  width: 100%;
  padding: 0;
  z-index: 1001;
}
.fh5co-nav .top-menu {
  padding: 22px 0;
}
.fh5co-nav .top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0px 0;
  margin-bottom: 0;
}
.fh5co-nav .top .num,
.fh5co-nav .top .fh5co-social,
.fh5co-nav .top .site {
  display: inline-block;
  margin: 0;
  padding: 5px 12px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .top .num,
  .fh5co-nav .top .fh5co-social,
  .fh5co-nav .top .site {
    padding: 5px 10px;
  }
}
.fh5co-nav .top .site {
  float: left;
  font-weight: 300;
  margin-top: 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 480px) {
  .fh5co-nav .top .site {
    display: none;
  }
}
.fh5co-nav .top .num {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  letter-spacing: 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08); /*2017-12-6*/
}
.fh5co-nav .top .fh5co-social {
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.fh5co-nav .top .fh5co-social li {
  font-size: 14px;
  display: inline-block;
}
.fh5co-nav .top .fh5co-social li a {
  padding: 7px 7px;
}
.fh5co-nav .top .fh5co-social li a i {
  font-size: 14px;
}
.fh5co-nav #fh5co-logo {
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 700;
  font-family: "Source Sans Pro", Arial, sans-serif;
}
.fh5co-nav #fh5co-logo a {
  color: #000;
  position: relative;
  padding-left: 25px;
}
/*2017-11-30*/
.fh5co-nav #fh5co-logo img {
  height: 30px;
}
.fh5co-nav #fh5co-logo a span {
  color: #000;
}
.fh5co-nav #fh5co-logo a i {
  position: absolute;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .menu-1 {
    display: none;
  }
}
.fh5co-nav ul {
  padding: 0;
  margin: 5px 0 0 0;
}
.fh5co-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-weight: 300;
}
.fh5co-nav ul li a {
  font-size: 14px;
  padding: 30px 15px;
  color: rgba(0, 0, 0, 0.9);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-nav ul li a:hover,
.fh5co-nav ul li a:focus,
.fh5co-nav ul li a:active {
  color: black;
}
.fh5co-nav ul li.has-dropdown {
  position: relative;
}
.fh5co-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.fh5co-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}
.fh5co-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}
.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}
.fh5co-nav ul li.has-dropdown:hover a,
.fh5co-nav ul li.has-dropdown:focus a {
  color: #000;
}
.fh5co-nav ul li.btn-cta a {
  padding: 30px 0px !important;
  color: #fff;
}
.fh5co-nav ul li.btn-cta a span {
  background: #2d6cdf;
  padding: 4px 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 1;
  -moz-border-radius: 1;
  -ms-border-radius: 1;
  border-radius: 1;
}
.fh5co-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}
.fh5co-nav ul li.active > a {
  font-weight: 400;
}
.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #444;
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle.fh5co-nav-white > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white > i::before,
.fh5co-nav-toggle.fh5co-nav-white > i::after {
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white.active > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white.active > i::before,
.fh5co-nav-toggle.fh5co-nav-white.active > i::after {
  background: #fff;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  right: 10px;
  top: 55px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  background: transparent;
  border: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

.codeimg {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 85%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  z-index: 9999;
}

.codeimg::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-top: none;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 5px solid transparent;
  border-style: 1px solid #ebeef5;
}

.img {
  width: 150px;
  height: 150px;
}
</style>
