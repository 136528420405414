<template>
  <div>
    <Head></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li>
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center slider-text">
                  <div class="slider-text-inner">
                    <h1 class="heading-section">联系我们</h1>
                    <p>
                      打造智慧园区，创建新型未来
                      <!-- <a href="http://www.cssmoban.com/" target="_blank" title="模板之家">模板之家</a> - Collect from <a href="http://www.cssmoban.com/" title="网页模板" target="_blank">网页模板</a> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>

    <div id="fh5co-contact">
      <div class="container">
        <div class="row">
          <div class="col-md-6 animate-box map">
            <div id="mapContainer"></div>
            <!-- <div id="amap">
             <el-amap vid="amap" class="amap" :center="center" :plugin="plugin">
             <el-amap-marker vid="component-marker" :position="position" />
             </el-amap>
             </div> -->
          </div>
          <div class="col-md-5 col-md-push-1 animate-box">
            <div class="fh5co-contact-info">
              <!-- <h3>联系地址</h3> -->
              <ul class="ul-class">
                <li class="icon-address">
                  <span class="iconfont">&#xe652;</span>&nbsp;
                  江苏省南京市经济技术开发区龙港科技园B1栋
                </li>
                <li class="icon-phone">
                  <span class="iconfont">&#xe62a;</span>&nbsp;
                  <a href="tel://025-85622270" style="font-size: 18px"
                    >025-85622270</a
                  >
                </li>
                <li class="icon-mail">
                  <span class="iconfont">&#xe66e;</span>&nbsp;
                  <a href="mailto:public@rmitec.cn" style="font-size: 18px"
                    >tech@njzhidian.com</a
                  >
                </li>
                <li class="icon-ie">
                  <span class="iconfont">&#xe62b;</span>&nbsp;
                  <a href="http://www.njzhidian.com" style="font-size: 18px"
                    >www.njzhidian.com</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      map: null,
      infoWindow: null, //信息窗体
      markers: [],
      mapList: [
        {
          name: "总部",
          address: "南京市栖霞区恒园路1号龙港科技园B1栋",
          lnglats: [118.866451, 32.155153],
        },
        {
          name: "雨花分部",
          address: "南京市雨花台区花神大道23号",
          lnglats: [118.778047, 31.972816],
        },
        {
          name: "上海分部",
          address: "上海市浦东新区临港新片区海基六路阿里云创新中心",
          lnglats: [121.908813, 30.866573],
        },
        {
          name: "北京分部",
          address: "北京市海淀区花园路甲13号",
          lnglats: [116.364181, 39.972333],
        },
      ],
    };
  },
  mounted() {
    this.initAMap();
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "916919393f49e9132baa000648891c5f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("mapContainer", {
            zoom: 14, //级别
            center: [118.86728, 32.15543], //中心点坐标
            viewMode: "3D", //使用3D视图
          });
          // this.initInfoWindow();
          this.setMapMarker();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 增加点标记
    setMapMarker() {
      // 创建 AMap.Icon 实例
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png", // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = [];
      this.mapList.forEach((item) => {
        // 遍历生成多个标记点
        let marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
          position: item.lnglats, // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        marker.content = `<div>${item.name}</div><div>${item.address}</div>`;
        marker.on("click", (e) => {
          this.openInfoWindow(e.target.content, e.lnglat);
        });
        makerList.push(marker);
      });
      this.map.add(makerList);
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    },
    // 打开信息窗口
    openInfoWindow(content, lnglat) {
      const infoWindow = new AMap.InfoWindow({
        content: content,
        position: lnglat,
      });
      infoWindow.open(this.map, lnglat);
    },
  },
};
</script>
<style lang="less" scoped>
.slides {
  width: 100%;
  height: 630px;
  li {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: rgb(255, 242, 0);
    background-image: url("../assets/images/img_bg_4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    .container {
      position: absolute;
      top: 40%;
      left: 10%;
      .heading-section {
        color: white;
      }
      p {
        color: white;
      }
    }
  }
}
.col-md-6 {
  width: 100%;
  height: 500px;
  #mapContainer {
    width: 100%;
    height: 100%;
  }
}
.col-md-5 {
  width: 100%;
  margin-left: -100px;
}
.fh5co-contact-info {
  width: 100%;
}
.ul-class {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  li {
    width: 50%;
    font-size: 18px;
  }
}
</style>
